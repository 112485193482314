@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&display=swap");
$gray: #b4b4b4;
$blue-dark: #025184;
:root {
  --gray: rgba(24, 129, 225, 0.4);
}

* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
  scrollbar-width: auto;
}

body {
  font-family: "Lexend", sans-serif;
}


/* Firefox */


/* Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #dbd5d5c9;
  border-radius: 10px;
  border: 2px solid #ffffff;
  &:hover {
    background-color: #767ffe;
  }
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 !important;
  margin: 0 !important;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

.text-gray {
  color: #A3A3A3;
}

.text-green {
  color: #72EE38;
}

.text-blue {
  color: #183972;
}

.text-orange {
  color: #FFB84E;
}

.text-lightblue {
  color: #407BFF;
}

.text-pink {
  color: #F86D6D;
}

.text-cyan {
  color: #39C9C1;
}

.text-purple {
  color: #B910BC;
}

.text-yellow {
  color: #E89416;
}

.text-gray {
  color: #8c8c8c;
}

.text-green {
  color: #0bcc41;
}

.text-red {
  color: #e31010;
}

.text-lightblue {
  color: #407bff;
}

.bg-lightgreen {
  background: #e2ffea;
}

.bg-blue {
  background: #025184;
}

.bg-lightblue {
  background: #2d98df;
}

.bg-green {
  background: #0bcc41;
}

.bg-pink {
  background: #ff4aa1;
}

.bg-lightpink {
  background: #fff1f1;
}

.bg-blue {
  background: #102ABC;
}

.bg-lightblue {
  background: #d0d7f7;
}

.bg-cyan {
  background: #39C9C1;
}

.bg-lightcyan {
  background: #ECFFFE;
}

.bg-purple {
  background: #B910BC;
}

.bg-lightpurple {
  background: #FEDDFF;
}

.bg-yellow {
  background: #E89416;
}

.bg-lightyellow {
  background: #FFF0D9;
}

.text-orange {
  color: #FF9C40;
}

.bg-orange {
  background: #FF9C40;
}

.bg-lightred {
  background: #f8cbcb;
}

.text-red {
  color: #F06060;
}

.bg-red {
  background: #F06060;
}

.bg-lightorange {
  background: #f7d8bbe0;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.w10 {
  width: 10%;
}

.w22 {
  width: 22%;
  max-width: 22%;
}

.w90 {
  width: 90%;
}

.w-200px {
  width: 200px;
}

.wh-20px {
  width: 20px !important;
  height: 20px !important;
}

.wh-40px {
  width: 40px !important;
  height: 40px !important;
}

.navbar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed !important;
  top: 0;
  right: 0;
  background: #ebeeff;
  z-index: 20;
}

.wrapper-box {
  padding-top: calc(52px + 30px);
}

.content-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 40px;
}

.wrapper {
  min-height: 100vh;
  position: relative;
  z-index: 18;
}

.bg-gray {
  background: #fcfcfc !important;
}

.grd-btn {
  flex: 1 1 auto;
  display: flex;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  background-image: linear-gradient( to right, #0e1f6e 0%, #246b9a 51%, #112edb 100%);
  border: none;
  border: 1px solid #112edb;
}

.grd-btn-disabled {
  flex: 1 1 auto;
  display: flex;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
}

.grd-btn:hover {
  background-position: right center !important;
}

.grdline-btn {
  padding: 10px 40px;
  transition: 0.5s;
  color: black;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #2a2a2a;
}

.grdline-btn:hover {
  background-position: right center !important;
  background-image: linear-gradient( to right, #0e1f6e 0%, #246b9a 51%, #112edb 100%);
  border: 1px solid #112edb;
  color: #fafafa;
}

.next-btn {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  color: #9a9a9a;
  &:hover {
    color: #0e2074;
  }
}

::placeholder {
  color: #2a2a2a !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2a2a2a;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2a2a2a;
}

form {
  input {
    background: transparent;
    border: none;
    outline: none;
    &:focus {
      border: none;
      background: none;
    }
  }
}

// ======= modal
.tracking-modal {
  .modal-header {
    border-bottom: none !important;
  }
  .input-box {
    input {
      border: none;
      outline: none;
      border-bottom: 0.5px solid #ccc;
      padding: 10px 0;
    }
     ::placeholder {
      color: #2a2a2a !important;
    }
  }
}

.input-box {
  input {
    border: none;
    outline: none;
    border-bottom: 0.5px solid #ccc;
    padding: 10px 0;
  }
   ::placeholder {
    color: #2a2a2a !important;
  }
}

.check-box {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(180deg, #0e2074 0%, #112dd4 100%);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.normal-tbl {
  table {
    border-collapse: collapse;
    width: 100%;
    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
  }
  tr:nth-child(odd) {
    background-color: #dddddd;
  }
}

.modify-tbl {
  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #e2e2e2;
    td,
    th {
      text-align: center;
      padding: 14px 8px;
      vertical-align: middle;
    }
  }
  tr:nth-child(odd) {
    background-color: #f6f6f6;
  }
}

.tbl-delete {
  border: none;
  padding: 0;
  background: none;
  &:hover {
    color: red;
  }
}

.upload-box {
  background: #f2f6f9;
  border-radius: 8px;
  h3,
  h6 {
    display: flex;
    justify-content: center;
    color: #949494;
  }
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
  }
  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding-bottom: 30px;
  }
}

.drag-box {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 50px;
  border: 2px dashed rgba(148, 148, 148, 0.7);
  border-radius: 6px;
  span {
    position: relative;
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    pointer-events: none;
    padding-top: 10px;
    color: #6fb0eb;
    a {
      color: #1881e1;
    }
  }
  input {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.file-import {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #f2f6f9;
  padding: 7px 9px;
  .file-importicon {
    width: 34px;
    min-width: 34px;
    height: 34px;
    margin: 5px;
    margin-right: 10px;
    &[data-src$=".pdf"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" viewBox="0 0 16 16"><path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/><path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/></svg>') no-repeat;
    }
    &[data-src$=".png"],
    &[data-src$=".gif"],
    &[data-src$=".jpg"],
    &[data-src$=".jpeg"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" viewBox="0 0 16 16"><path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/><path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/></svg>') no-repeat;
    }
    &[data-src$=".doc"],
    &[data-src$=".docx"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" class="bi bi-file-earmark-word" viewBox="0 0 16 16"><path d="M5.485 6.879a.5.5 0 1 0-.97.242l1.5 6a.5.5 0 0 0 .967.01L8 9.402l1.018 3.73a.5.5 0 0 0 .967-.01l1.5-6a.5.5 0 0 0-.97-.242l-1.036 4.144-.997-3.655a.5.5 0 0 0-.964 0l-.997 3.655L5.485 6.88z"/><path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/></svg>') no-repeat;
    }
  }
  span {
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    color: #6a738d;
    font-weight: bold;
    &:hover {
      color: blue;
    }
    &:active {
      color: green;
    }
  }
  .icon {
    cursor: pointer;
    &:hover {
      color: blue;
    }
    &:active {
      color: green;
    }
  }
}

.upload-btn {
  color: #ffffff !important;
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%);
  border: 1px solid #112ed8 !important;
  border-radius: 29px;
  font-size: 14px;
  font-weight: 600;
  padding: 9px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-line {
  background: none;
  padding: 0;
  border: none;
  text-decoration: underline;
  color: #5073b6;
  font-weight: 700;
  font-size: 20px;
  &:hover {
    color: #0c1faa;
    transition: 500ms;
  }
}

.image-container {
  position: relative;
  display: inline-block;
  border-radius: 2px;
  img {
    width: 75px;
    height: 70px;
  }
  .tbl-delete {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
    padding-top: 0.5px;
    background-color: #fff;
    opacity: 0.8;
    border-radius: 2px;
    color: black;
    &:hover {
      color: red;
    }
  }
}

.dotted-line {
  border: 1px dashed rgba(0, 0, 0, 0.223);
  padding: 5px;
  border-radius: 4px;
  background: #dfe0e9d0;
}

.signin-box {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
}

.signin-left {
  background: #ffffff;
  height: 100%;
  min-height: 100%;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.signin-right {
  background: #f9f9f9;
  height: 100%;
  width: 100%;
  padding: 80px 80px 40px 80px !important;
  .btn-box {
    background: #eeeeee;
    border-radius: 6px;
    padding: 8px;
    button {
      background: transparent;
      border: none;
      padding: 8px;
      border-radius: 5px;
      &:hover,
       :active {
        background: #ffffff;
      }
    }
  }
  .anchor {
    a {
      text-decoration: none;
      color: #055687;
    }
    .line {
      text-decoration: underline;
    }
  }
}

.input-shadow {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.br-shadow {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}

::placeholder {
  color: #e0e0e0 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e0e0e0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #e0e0e0;
}

.vision {
  position: absolute;
  top: 8px;
  right: 10px;
  border: none;
  background: none;
  .icon {
    color: #515456;
  }
}

// New Css 11/09/2023
.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}

.tbl-edit {
  border: none;
  padding: 0;
  background: none;
  &:hover {
    color: blue;
  }
}

.normal-btn {
  background: none;
  border: 1px solid #000000;
  padding: 6px 22px;
  font-weight: 400;
  font-size: 18px;
  &:hover {
    // border: 1px solid #0E207B;
    border: 1px solid #246b9a;
    color: #ffffff;
    background-image: linear-gradient( to right, #0e1f6e 0%, #246b9a 51%, #112edb 100%);
  }
}

.edit-btn {
  background: none;
  border: 1px solid #000000;
  padding: 2px 22px;
  font-weight: 400;
  font-size: 18px;
  background-image: linear-gradient( to right, #0e1f6e 0%, #246b9a 51%, #112edb 100%);
  color: #ffffff;
  &:hover {
    // border: 1px solid #0E207B;
    border: 1px solid #246b9a;
    color: #ffffff;
    background-image: linear-gradient( to right, #0e1f6e 0%, #246b9a 51%, #112edb 100%);
  }
}

.submit-btn {
  background: none;
  border: 1px solid #000000;
  padding: 6px 22px;
  font-weight: 400;
  font-size: 18px;
  background-image: linear-gradient( to right, #0e1f6e 0%, #246b9a 51%, #112edb 100%);
  color: #ffffff;
  &:hover {
    // border: 1px solid #0E207B;
    border: 1px solid #246b9a;
    color: #ffffff;
    background-image: linear-gradient( to right, #0e1f6e 0%, #246b9a 51%, #112edb 100%);
  }
  &:disabled {
    background: #9ba5d5;
    color: #ffffff;
  }
}

.action-btn {
  height: 200px;
  background: none;
  border-radius: 15px;
  border: 1px solid #d2d2d2;
  font-weight: 700;
  font-size: 24px;
  box-shadow: 0px 4px 30px 0px #00000026;
  transition: 1000ms;
  &:hover,
   :active {
    // background-image: linear-gradient(to right, #0E1F6E 0%, #246b9a 51%, #112EDB 100%);
    color: #ffffff;
    background: rgb(11, 21, 76);
    background: linear-gradient( 354deg, rgba(11, 21, 76, 1) 6%, rgba(19, 47, 183, 1) 96%);
  }
}

.border-boxs {
  div {
    border-right: 1px solid #cccccc;
    padding: 5px;
  }
  div:last-child {
    border: none;
  }
}

.br-left {
  border-left: 1px solid #e2e2e2;
}

.arriv-btn {
  width: 100%;
  border: 1px solid #d2d2d2;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 4px 30px 0px #00000026;
  padding: 10px;
}

.sku-btn {
  border: 1px solid #000000;
  background: none;
  transition: 500ms;
  &:hover {
    color: #ffffff;
    border: 1px solid rgba(19, 47, 183, 1);
    background: rgb(11, 21, 76);
    background: linear-gradient( 354deg, rgba(11, 21, 76, 1) 6%, rgba(19, 47, 183, 1) 96%);
  }
}

.plus-btn {
  background: none;
  &:hover {
    color: rgba(19, 47, 183, 1);
  }
}

.cross-btn {
  &:hover {
    color: rgba(19, 47, 183, 1);
  }
}

.disable-btn {
  border: none;
  background: #9ba5d5;
  color: #ffffff;
}

.tabs-row {
  .nav-pills {
    display: flex;
    justify-content: space-between;
    .nav-item {
      background: #ffffff;
      width: 48%;
      .nav-link {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}

.three-tabs-row {
  .nav-pills {
    display: flex;
    justify-content: space-between;
    .nav-item {
      background: #ffffff;
      width: 32%;
      .nav-link {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}

.nav-item .nav-link {
  background: none;
  border: 1px solid #000000 !important;
  border-radius: 10px !important;
  color: #2a2a2a !important;
  padding: 8px 20px !important;
  width: 100%;
  &:hover {
    background: linear-gradient( 90deg, #0e2078 -0.3%, #112ed8 130.42%) !important;
    border: 1px solid #112ed8 !important;
    color: #ffffff !important;
    transition: 400ms;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #ffffff !important;
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%);
  border: 1px solid #112ed8 !important;
  border-radius: 29px;
  pointer-events: none;
}

.arrow-btn {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  &:hover {
    color: #1881e1;
  }
}

.arriv-box {
  width: 100%;
  border: 1px solid #d2d2d2;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 4px 30px 0px #00000026;
  padding: 10px;
  text-align: center;
}

.eye-btn {
  position: relative;
  button {
    background: transparent;
    position: absolute;
    right: 20px;
    &:hover {
      color: #1881e1;
    }
  }
}

.eye-btn1 {
  position: relative;
  button {
    background: transparent;
    position: absolute;
    right: 5px;
    top: 46%;
    transform: translate(-50%, -50%);
    &:hover {
      color: #1881e1;
    }
  }
}

.eye-btn2 {
  button {
    background: transparent;
    &:hover {
      color: #1881e1;
    }
  }
}

.back-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  &:hover {
    color: #e2b211;
  }
}

.damage-card-container {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  .damage-card {
    border: 1px solid #d2d2d2;
    min-width: 15px;
    max-width: max-content;
    display: flex;
    gap: 4px;
  }
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

// new css 16/02/2024
.btn-card {
  background: white;
  display: flex;
  text-align: center;
  justify-content: center;
  border: 1px solid #D2D2D2;
  box-shadow: 0px 4px 20px 0px #0000001A;
  border-radius: 20px;
  transition: 0.5s;
  font-family: M PLUS 1;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  &:hover {
    background-image: linear-gradient(to right, #0E1F6E 0%, #246b9a 51%, #112EDB 100%);
    color: #fff;
  }
  .btn-arrow {
    position: absolute;
    top: 25%;
    right: 40px;
    font-size: 40px;
  }
}

.btn-back {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  &:hover {
    color: #2d98df;
  }
}

.btn-nrml {
  border: 1px solid #000;
  background: none;
  &:hover {
    background-image: linear-gradient(to right, #0E1F6E 0%, #246b9a 51%, #112EDB 100%);
    color: #fff;
    border: 1px solid transparent;
  }
  &:disabled {
    pointer-events: none;
  }
}

.new-tbl {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    td,
    th {
      text-align: start;
      padding: 12px 10px;
    }
    th {
      background-color: #F6F6F6;
    }
    td {
      padding: 10px 10px;
    }
  }
  tr:nth-child(odd) {
    background-color: #FBFBFB;
    border: 1px solid #E2E2E2;
  }
}

.accor-btn {
  border: none;
  outline: none;
  background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%);
  border-radius: 8px;
  color: #fff;
  padding: 8px 18px;
  font-family: M PLUS 1;
  font-size: 16px;
  font-weight: 400;
  // z-index: 10 !important;
  &:disabled {
    background: linear-gradient(90deg, #575c74 -0.3%, #7688ee 130.42%);
  }
}

.z-10 {
  z-index: 10 !important;
}

.select-img {
  justify-content: center;
  justify-items: center;
  .img-box {
    width: 90px;
    height: 100px;
    border-radius: 8px;
    border: none;
    padding: 5px;
    background: none;
    outline: none;
    &:hover,
     :active {
      border: 2px solid #4FCE5C;
    }
    div {
      border-radius: 8px;
      height: 100%;
      box-shadow: 0px 2px 7px 0px #00000026;
      border: 1px solid #E1E1E1;
      overflow: hidden;
      img {
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}

.view-img {
  width: 100%;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

// accordion button in center align
.accordion-header {
  position: relative;
  margin-bottom: 20px;
  .accordion-button {
    background: #F3F6FF !important;
    font-family: Lexend;
    font-size: 20px;
    font-weight: 400;
    padding: 0 10px;
  }
  .accor-btn {
    position: absolute;
    top: 50%;
    left: 30%;
    z-index: 2;
    background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%);
    transform: translate(-50%, -50%);
    &:disabled {
      background: linear-gradient(90deg, #575c74 -0.3%, #7688ee 130.42%);
    }
  }
  .accor-track {
    position: absolute;
    top: 50%;
    right: 0%;
    z-index: 2;
    transform: translate(-50%, -50%);
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid #000;
    background: none;
    z-index: 10 !important;
    &:hover {
      background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%);
      color: #fff;
      border: 1px solid #112ED8;
    }
  }
  .accor-receive {
    position: absolute;
    top: 50%;
    right: 0%;
    z-index: 2;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 600;
    padding: 5px 10px;
    z-index: 10 !important;
  }
  .accor-tracktwo {
    position: absolute;
    top: 50%;
    right: 15%;
    z-index: 2;
    transform: translate(-50%, -50%);
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid #000;
    background: none;
    z-index: 10 !important;
    &:hover {
      background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%);
      color: #fff;
      border: 1px solid #112ED8;
    }
  }
}

.list-boxes {
  background: #F3F6FF;
  border-radius: 20px;
  ul {
    list-style-type: none;
    padding: 10px 0;
    margin: 0;
    li {
      padding: 10px 20px;
      font-family: Lexend;
      font-size: 20px;
      font-weight: 400;
      border-bottom: 1px solid #D4D4D4;
    }
    li:last-child {
      border-bottom: none;
    }
  }
}


/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

.rounded-checkbox {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  // vertical-align: middle;
  border: 1px solid rgb(212, 206, 206);
  background: #FFFFFF;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.rounded-checkbox:checked {
  appearance: auto;
  // clip-path: circle(50% at 50% 50%);
  clip-path: circle(48%);
  // background-color: #112BC2;
  background-color: #0b23a6;
}

.rounded-checkbox:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

// green checkbox
.checkbox {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.checkbox__input {
  position: absolute;
  top: -100px;
  left: -100px;
}

.checkbox__inputred {
  position: absolute;
  top: -100px;
  left: -100px;
  &:disabled {
    cursor: not-allowed;
    ~.checkbox__inner {
      opacity: 0.25;
      cursor: not-allowed;
      background-color: #626262;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='16px' viewBox='0 0 14 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3Echeck%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ios_modification' transform='translate(-27.000000, -191.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group-Copy' transform='translate(0.000000, 164.000000)'%3E%3Cg id='ic-check-18px' transform='translate(25.000000, 23.000000)'%3E%3Cpolygon id='check' points='6.61 11.89 3.5 8.78 2.44 9.84 6.61 14 15.56 5.05 14.5 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
}

.checkbox__inner {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #626262;
  background: transparent no-repeat center;
}

.checkbox__input:checked+.checkbox__inner {
  border-color: #11C223;
  background-color: #11C223;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='16px' viewBox='0 0 14 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3Echeck%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ios_modification' transform='translate(-27.000000, -191.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group-Copy' transform='translate(0.000000, 164.000000)'%3E%3Cg id='ic-check-18px' transform='translate(25.000000, 23.000000)'%3E%3Cpolygon id='check' points='6.61 11.89 3.5 8.78 2.44 9.84 6.61 14 15.56 5.05 14.5 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 20px 16px;
}

.checkbox__inputred:checked+.checkbox__inner {
  border-color: #E4351D;
  background-color: #E4351D;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='16px' viewBox='0 0 14 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3Echeck%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ios_modification' transform='translate(-27.000000, -191.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group-Copy' transform='translate(0.000000, 164.000000)'%3E%3Cg id='ic-check-18px' transform='translate(25.000000, 23.000000)'%3E%3Cpolygon id='check' points='6.61 11.89 3.5 8.78 2.44 9.84 6.61 14 15.56 5.05 14.5 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 20px 16px;
}

// 
.w25px {
  width: 25px;
}

.h25px {
  height: 25px;
}

.bg-blue {
  background: #102ABC;
}

.text-blue {
  color: #102ABC;
}

.br-left {
  border-left: 1px solid black;
}

.br-right {
  border-right: 1px solid black;
}

.datepicker {
  z-index: 9999 !important
}